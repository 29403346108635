import React, {useState} from 'react';
import {Button, Select, Spin, Table, Tag} from "antd";
import useRouteQuery from "../../../../../../../Utils/useRouterQuery";
import {
    SelectOutlined
} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {
    useCheckInvoices,
    useCompanyData,
    useGetHistorySubscription,
} from "../../../../../../../Hooks/TT_ELD/Companies";
import moment from "moment";

type numStr = string | number

type MyObjectType = {
    [key: string | number]: any;
};

interface approvalSource {
    no: numStr,
    id: numStr
    amount: string
    status: numStr,
    createdAt: numStr,
    action: { id: numStr, note: string };
    key: React.Key;
}

const {Option} = Select;
type params = {
    readonly id: any
}
const statusList = [
    {
        label: "Paid",
        value: 'paid'
    },

    {
        label: "Unpaid",
        value: 'open',
    },

]
type Data = {
    data?: {
        data: any
        total: any
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: boolean
}

const SubscriptionUpdate = () => {
    const [status, setStatus] = useRouteQuery("paid", 'status', (v) => String(v));
    const [skip, setSkip] = useState<string>('1');
    const {id} = useParams<params>();
    const [getCompanyId, setCompanyId] = useState<string | number | undefined>()
    const company: MyObjectType = useCompanyData(id);
    // const userMe: MyObjectType = useUsersMe(id);
    const [next, setNext] = useState<object>({value: false, lastInvoiceId: ''});
    const checkInvoices: MyObjectType = useCheckInvoices(getCompanyId)
    const {
        data,
        refetch,
        isLoading,
        isFetching
    }: Data = useGetHistorySubscription(status, next, id);
    const onChange = (query: any) => {
        setSkip(query.current);
    }
    const handleCheckInvoices = () => {
        setCompanyId(id)
        checkInvoices?.refetch()
    }
    const [currentPage, setCurrentPage] = useState(0);
    const editCurrentPage = (prev: any) => {
        if (prev) {
            setNext({value: false, lastInvoiceId: data?.data[0]?.id});
            setCurrentPage(val => val - 1);
        } else {
            setNext({value: true, lastInvoiceId: data?.data[data?.data.length - 1]?.id});
            setCurrentPage(val => val + 1);
        }
    };
    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a: any, b: any) => a.no - b.no,
                multiple: 1,
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (tag: string) => (
                <Tag color={tag === "paid" ? 'green' : 'red'}>
                    {tag?.toUpperCase()}
                </Tag>
            ),
        },

        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',

        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            fixed: 'right',
            render: ({id, note}: { id: string | number, note: string }) => {

                return (
                    <>
                        {<Link target="_blank" to={note}><Button type="primary">Open Invoice <SelectOutlined
                            rev={undefined}/></Button></Link>}
                    </>
                )
            }

        },
    ]
    return (
        <div className='mainPage'>
               <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                 <Select value={status ? status : 'all'} defaultValue="pending" onChange={(value: any, option) => {
                     setStatus(value === 'all' ? '' : value)
                     setSkip('1')}} style={{width: 200}}>
                     {statusList.map((u: any, index: any) => <Option key={index} value={u.value}>{u.label}</Option>)}
                 </Select>
                       <Button type='primary' size={'large'} onClick={() => handleCheckInvoices()}
                               style={{marginLeft: 'auto'}}>Check Invoices</Button>
                   <Button size={'large'} onClick={refetch} style={{marginLeft: '15px'}}>Refresh</Button>
            </span>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table style={{margin: '15px 0 15px 0'}} onChange={onChange}
                       dataSource={data?.data.map((u: any, i: number): approvalSource => {
                           const invoiceAmount = u.total < 0 ? '-' : `$${Math.abs(u.total) / 100}`;
                           const formattedDate2 = moment.unix(u.created).tz(company.data?.tz?.value).format('MMMM Do YYYY, h:mm:ss a');

                           const obj: approvalSource = {
                               no: i + 1,
                               id: u.id,
                               amount: invoiceAmount,
                               status: u.status,
                               createdAt: formattedDate2,
                               action: {id: u.id, note: u.hosted_invoice_url},
                               key: u.id
                           }
                           return obj
                       })} columns={columns} pagination={false}/>
                <span
                    style={{display: 'flex', margin: '15px 0 0px 0', alignItems: 'center', justifyContent: "flex-end"}}>
                    <Button style={{margin: '0px 15px 0px 0'}} disabled={currentPage === 0}
                            onClick={() => editCurrentPage(true)}>Previous</Button>
                    <Button disabled={(currentPage + 1) * 10 >= data?.total}
                            onClick={() => editCurrentPage(false)}>Next</Button>
                  </span>

            </Spin>
        </div>
    );
};

export default SubscriptionUpdate;